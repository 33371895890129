<template>
	<sup>
		<b-button class="p-0 bg-transparent border-0 text-dark info-btn z-small-text" v-b-tooltip.hover="{ variant: 'white', customClass: 'z-tooltip' }" :title="text"><b-icon :icon="icon"></b-icon></b-button>
	</sup>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			default: 'question-circle-fill'
		}
	}
}
</script>

<style lang="sass" scoped>
.z-tooltip
	opacity: 1 !important

	.tooltip-inner
		background: #fff
		color: #54514B
		border: 1px solid #E1DED6
		border-radius: 2px
		padding: 15px

	.arrow
		&:before
			border-top-color: #E1DED6

.info-btn
	font-size: 13px
	line-height: 1
	margin-top: -12px
</style>
